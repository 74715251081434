import { State } from "xstate";
import {
  FiatPrice,
  ItinerarySeats,
  SeatMapAvailable,
  SeatSlice,
} from "@b2bportal/air-booking-api";
import { SeatMapCallback } from "@b2bportal/air-seats-api";
import { Product } from "@b2bportal/purchase-api";
import { getChildState, getParentState } from "@checkout/helpers";
import { ParentState, SeatSelectionState } from "@checkout/types";
import { getCurrencySymbol } from "@hopper-b2b/utilities";
import { getQuotedFlightProduct } from "../../selectors";
import { FlightContext } from "../../types";

type SeatStateType = State<FlightContext>;
type SeatStateWithoutValue = Pick<SeatStateType, "context">;

export const getIsSeatSelectionLoading = (state: SeatStateType) => {
  const parentState = getParentState(state.value);
  const childState = getChildState(state.value);

  return (
    parentState === ParentState.seatSelection &&
    [
      SeatSelectionState.loading,
      SeatSelectionState.route,
      SeatSelectionState.validate,
    ].includes(childState as SeatSelectionState)
  );
};

export const getSeatMapRequest = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].seatMapRequest as SeatMapCallback;

export const getSeatMapHtml = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].seatMapHtml;

export const getCheapestSeat = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].cheapestSeat;

export const getSelectedSeats = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].selectedSeats;

export const getSelectedSeatSegments = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].selectedSeatSegments;

export const getSeatsInfo = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].seatsInfo;

export const getPassengerSeatMap = (
  state: SeatStateWithoutValue
): Map<string, number> => {
  const seats = getSelectedSeats(state);
  const map = new Map();
  seats.forEach((seat) => {
    const total = map.get(seat.person_id);
    const seatPrice =
      seat.price_object.total / 10 ** seat.price_object.decimal_places;
    if (total) {
      map.set(seat.person_id, total + seatPrice);
    } else {
      map.set(seat.person_id, seatPrice);
    }
  });
  return map;
};

export const getSeatTotalPricing = (state: SeatStateWithoutValue) => {
  //TODO: remove this when all tenants move to hopper seats
  const passengerSeatMap = getPassengerSeatMap(state);
  const gordianTotal = Array.from(passengerSeatMap.values()).reduce(
    (currentTotal, seatTotal) => {
      return currentTotal + seatTotal;
    },
    0
  );

  const hopperTotal =
    state.context[ParentState.seatSelection].selectedSeatTotal?.amount;

  return hopperTotal ?? gordianTotal;
};

export const getSeatTotalFiatPricing = (
  state: SeatStateWithoutValue
): FiatPrice | undefined => {
  const seats = getSelectedSeats(state);
  if ((seats?.length ?? 0) === 0) {
    return undefined;
  }

  const currencyCode = seats[0].price_object.currency;
  const currencySymbol = getCurrencySymbol(currencyCode);
  const totalPricing = getSeatTotalPricing(state);

  return {
    currencyCode: currencyCode,
    currencySymbol: currencySymbol,
    value: totalPricing,
  };
};

export const getSeatSelectionVisited = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].visited;

export const getSeatMapAvailability = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].seatMapAvailable;

export const getSkipSeatSelection = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].skipSeatSelection;

export const getSeatSelectionError = (state: SeatStateWithoutValue) =>
  state.context[ParentState.seatSelection].error;

export const getSeatSelectionErrorOpen = (state: SeatStateType) => {
  const parentState = getParentState(state.value);
  const childState = getChildState(state.value);

  return (
    parentState === ParentState.seatSelection &&
    childState === SeatSelectionState.error
  );
};

export const getFulfilledSeats = (
  state: SeatStateWithoutValue
): ItinerarySeats | undefined => {
  const products = state.context[ParentState.cartFulfill].fulfilledProducts;
  const seatProduct = products?.find((p) => p.type === Product.Seats)?.value;
  return seatProduct;
};

export const getSeatSlices = (
  state: SeatStateWithoutValue
): Array<SeatSlice> | undefined => {
  const seatsProduct = getQuotedFlightProduct(state)
    ?.seatMap as SeatMapAvailable;

  return seatsProduct?.seatsInfo?.slices;
};
