import {
  PATH_HOME,
  PATH_FLIGHTS,
  PATH_FLIGHTS_SEARCH,
} from "@hopper-b2b/common-utils";

export const SUPPORT_LINK = "Support";
export const SUPPORT_LINK_URL = "https://support-link-url.com";
export const DARK_HEADER_ROUTES = [
  PATH_HOME,
  `${PATH_HOME}${PATH_FLIGHTS}`,
  `${PATH_HOME}flights`,
  `${PATH_HOME}${PATH_FLIGHTS_SEARCH}`,
  `${PATH_HOME}flights/search`,
];
