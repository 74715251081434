import {
  Airline,
  Airport,
  BookedFlightItinerary,
  BookedFlightItineraryWithDepartureTime,
  Coordinates,
  FlightItinerarySegment,
  GeopoliticalInfo,
} from "@b2bportal/air-booking-api";
import { TFunction } from "i18next";
import { PassengerTypes } from "../..";
import { Person, TripSegment } from "../apis";
import { RewardsAmount } from "../apis/tysons/payment-machine";
import { SegmentShelf } from "../apis/tysons/shop-summary";
import {
  BaggageInfoEnum,
  DateTime,
  FiatPrice,
  PenaltiesInfoEnum,
  Prices,
  Uuid,
} from "../common";
import { AirRestrictionStatus, Restriction } from "../flights";

export type Adult = PassengerType;

export interface AdultWithLapInfant {
  adult: AssociatedPassenger;
  infant: AssociatedPassenger;
}

export interface AgentLocator {
  unscopedValue: string;
}

export interface City {
  coordinates: Coordinates;
  name: string;
  /**
   * 3 character IATA city code
   * @type {string}
   * @memberof City
   */
  code: string;
  servingAirports: Array<Airport>;
  geography: GeopoliticalInfo;
}

export type AirlineMap = {
  [airlineCode in string]?: Airline;
};

export type AirportMap = {
  [airportCode in string]?: Airport;
};

export interface CityMap {
  [cityCode: string]: City;
}

export interface Allowed extends BaggageInfoBase {
  allowance: BaggageAllowance;
  charges: BaggageCharge[];
}

export interface AmadeusBrand {
  brandId: string;
  brandName?: string;
}

export interface Amount {
  amount: number;
  currency: string;
}

export interface AssociatedContact {
  nameNumber: NameNumber;
  contact: Contact;
}

export interface AssociatedPassenger {
  nameNumber: NameNumber;
  type: PassengerTypes;
  person: Person;
}

export interface BaggageAllowance {
  pieces: number;
  descriptions: string[];
  maximumMass?: Mass;
}

export interface BaggageCharge {
  amount: number;
  currency: string;
  firstPiece: number;
  lastPiece: number;
  descriptions: string[];
  maximumMass?: Mass;
}

export type BaggageInfo = Allowed | NotAllowed | Unknown;

export interface BaggageInfoBase {
  BaggageInfo: BaggageInfoEnum;
}

export enum MultiTicketTypeEnum {
  Single = "single",
  HackerFare = "hackerFare",
  VI = "virtualInterline",
}

export type IPaymentAmountInfo =
  | FiatAmountInfo
  | RewardsAmountInfo
  | SplitAmountInfo;

export enum PaymentTypeEnum {
  FiatAmountInfo = "FiatAmountInfo",
  RewardsAmountInfo = "RewardsAmountInfo",
  SplitAmountInfo = "SplitAmountInfo",
}

export interface FiatAmountInfo {
  PaymentAmountInfo: PaymentTypeEnum.FiatAmountInfo;
  numberDisplay: string;
  amount: Amount;
}

export interface RewardsAmountInfo {
  PaymentAmountInfo: PaymentTypeEnum.RewardsAmountInfo;
  accountDisplayName: string;
  amount: Omit<RewardsAmount, "PaymentAmount">;
}

export interface SplitAmountInfo {
  PaymentAmountInfo: PaymentTypeEnum.SplitAmountInfo;
  fiatInfo: {
    numberDisplay: string;
    amount: Amount;
  };
  rewardsInfo: {
    accountDisplayName: string;
    amount: Omit<RewardsAmount, "PaymentAmount">;
  };
}

export type Payment = CreditCard;

export enum PaymentEnum {
  CreditCard = "CreditCard",
}

export type Availability =
  | Available
  | AvailableAfter
  | AvailableUntil
  | Unavailable;

export enum AvailabilityEnum {
  Available,
  AvailableAfter,
  AvailableUntil,
  Unavailable,
}

export enum Reason {
  AlreadyDone,
  ContactAirline,
  ContactSupport,
  NotAllowed,
}

export interface BaseAvailability {
  Availability: AvailabilityEnum;
}

export interface Unavailable extends BaseAvailability {
  reason: Reason;
}

export type Available = BaseAvailability;

export interface AvailableAfter extends BaseAvailability {
  time: string;
}

export interface AvailableUntil extends BaseAvailability {
  time: string;
}

/**
 * @deprecated
 * @see Status in @b2bportal/air-booking-api
 */
export enum PortalItineraryStatusEnum {
  Canceled = "Canceled",
  Confirmed = "Confirmed",
  Modified = "Modified",
  Pending = "Pending",
}

export interface CfarId {
  productId: string;
  policyId: string;
}

export interface VoidExtension {
  days: number;
  at: string;
}

export interface Cfar {
  id: CfarId;
  voidExtension?: VoidExtension;
  created: string;
  expired: string;
}

export interface DisruptionId {
  productId: string;
  policyId: string;
}

export interface VoidExtension {
  days: number;
  at: string;
}

export interface Disruption {
  id: DisruptionId;
  voidExtension?: VoidExtension;
  created: string;
  expired: string;
}

export interface TripAncillaryContracts {
  cfar?: Cfar;
  missedConnection?: Disruption;
  delay?: Disruption;
}

export type Cancelled = FlightItinerarySegmentStatus;
export type Confirmed = FlightItinerarySegmentStatus;

export type ConfirmedPendingNewChange = FlightItinerarySegmentStatus;

export interface Contact {
  phoneNumber: string;
  emailAddress: string;
  givenName?: string;
  surname?: string;
}

export interface CountryCode {
  code: string;
}

export interface CreditCard {
  id: string;
  token: string;
  firstName: string;
  lastName: string;
  cardType: string;
  numberDisplay: string;
  month: number;
  year: number;
  zip: string;
  country: string;
  alias?: string;
  createdAt: string;
  updatedAt?: string;
  scanned: string;
  last4?: string;
  bin?: string;
  Payment: PaymentEnum;
}

export interface FareBasis {
  code: string;
}

export interface FareBrand {
  sabreFareBrand?: SabreBrand;
  travelportFareBrand?: TravelportBrand;
  gdxFareBrand?: GdxBrand;
  amadeusFareBrand?: AmadeusBrand;
}

export interface FareRule {
  title: string;
  text: string;
}

export interface FareRuleIndex {
  fareBasisCode: FareBasis;
  segment: number;
}

export interface FareRules {
  rules: ItineraryFareRules[];
}

export interface FlightItineraryRequest {
  states: FlightItineraryState[];
  referenceDateTime: string;
}

export interface FlightItinerarySegmentStatus {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export enum FlightItinerarySegmentStatusEnum {
  Confirmed = "Confirmed",
  UnMapped = "UnMapped",
  UnMappedPersisted = "UnMappedPersisted",
  Pending = "Pending",
  Declined = "Declined",
  ConfirmedPendingNewChange = "ConfirmedPendingNewChange",
  Cancelled = "Cancelled",
}

export interface FlightItinerarySlice {
  segments: FlightItinerarySegment[];
  restrictions?: Restrictions;
  fareShelf?: SegmentShelf;
}

export enum FlightItineraryState {
  Canceled = "Canceled",
  Past = "Past",
  Future = "Future",
  Invalid = "Invalid",
  Present = "Present",
}

export interface GdxBrand {
  brandId: string;
  brandName?: string;
}

export enum Gender {
  F = "F",
  M = "M",
}

export interface ItineraryFareRules {
  fareRuleIndex: FareRuleIndex;
  rules: FareRule[];
}

export interface Known extends PenaltiesInfo {
  changePenalty: Penalty;
  cancelPenalty: Penalty;
}

export interface Location {
  locationCode: string;
  terminalName?: string;
  terminalCode?: string;
}

export interface Mass {
  value: number;
  unit: string;
}

export interface MultiProvider {
  agent: AgentLocator;
  children: SingleProvider[];
}

export type TravelItinerary = MultiTravelItinerary | SingleTravelItinerary;

export interface TravelItineraryBase {
  TravelItinerary: TravelItineraryEnum;
}

export interface MultiTravelItinerary extends TravelItineraryBase {
  locators?: MultiProvider;
  travelItineraries: SingleTravelItinerary[];
}

export interface NameNumber {
  value: string;
}

export type NoPenalty = Penalty;

export type NotAllowed = BaggageInfoBase;

export type NotPossible = Penalty;

export interface PassengerTripPricing {
  currency: string;
  baseWithoutMargin: Prices;
  taxes: Prices;
  passengerFee: Prices;
  passengerType: PassengerTypeEnum;
  additionalMargin?: Prices;
  person: Person;
  seats: Seat[];
  subtotal: Prices;
  total: Prices;
}

export interface PassengerType {
  PassengerType: PassengerTypeEnum;
}

export enum PassengerTypeEnum {
  AgelessChild = "AgelessChild",
  LapInfantPrivate = "LapInfantPrivate",
  LapInfant = "LapInfant",
  SeatedInfant = "SeatedInfant",
  AgelessChildPrivate = "AgelessChildPrivate",
  AdultPrivate = "AdultPrivate",
  AgeChild = "AgeChild",
  Adult = "Adult",
  SeatedInfantPrivate = "SeatedInfantPrivate",
}

export interface PenaltiesInfo {
  PenaltiesInfo: PenaltiesInfoEnum;
}

export interface Penalty {
  Penalty: PenaltyEnum;
}

export enum PenaltyEnum {
  NoPenalty = "NoPenalty",
  NotPossible = "NotPossible",
  Unknown = "Unknown",
  WithFee = "WithFee",
}

export type Pending = FlightItinerarySegmentStatus;

export interface PhoneNumber {
  value: string;
}

export enum PostTicketingStatus {
  Cancelled = "Cancelled",
  Modified = "Modified",
  Rejected = "Rejected",
  Ticketed = "Ticketed",
}

export interface PurchaseTimeDiscount {
  discount: number;
  lineItemName: string;
}

export interface Rejected {
  PostTicketingStatus: PostTicketingStatus;
}

export interface Restrictions {
  penaltiesInfo: PenaltiesInfo;
  baggageInfo: BaggageInfo;
  seatMapInfoAvailable: boolean;
}

export interface SabreBrand {
  brandId: string;
  brandName?: string;
}

export interface Seat {
  amount: Amount;
  id: string;
}

export interface SeatedPassengers {
  withLapInfants: AdultWithLapInfant[];
  alone: AssociatedPassenger[];
  contact: AssociatedContact;
}

export interface SegmentAirlineInfo {
  code: string;
  flightNumber: number;
  locator?: string;
}

export interface SingleProvider {
  agent: AgentLocator;
}

export interface SingleTravelItinerary extends TravelItineraryBase {
  locators?: SingleProvider;
  slices: FlightItinerarySlice[];
  ticketing: Ticketing[];
  postTicketingStatus?: PostTicketingStatus;
  supportContact: string;
}

export interface TicketNumberType {
  TicketNumberType: TicketNumberTypeEnum;
}

export enum TicketNumberTypeEnum {
  InfantNotIssued = "InfantNotIssued",
  TicketNumber = "TicketNumber",
}

export interface Ticketing {
  eTicketNumber: TicketNumberType;
}

export enum AncillaryKindEnum {
  MissedConnection = "MissedConnection",
  Cfar = "Cfar",
  Luggage = "Luggage",
  Insurance = "Insurance",
  Chfar = "Chfar",
  Delay = "Delay",
  CancelOrChange = "CancelOrChange",
}

export interface TotalTripPricing {
  currency: string;
  baseWithoutMargin: Prices;
  taxes: Prices;
  passengerFee: Prices;
  additionalMargin?: Prices;
  discounts: PurchaseTimeDiscount[];
  tripFee: Prices;
  total: Prices;
  seats: Seat[];
}

export interface TravelCredit {
  credit: Amount;
  id: Uuid;
  penalty?: Amount;
  rebookDeadline?: DateTime;
  route: Location[];
  status: TravelCreditStatus;
  travelDeadline?: DateTime;
}

export enum TravelCreditStatus {
  Available = "Available",
  Expired = "Expired",
  Redeemed = "Redeemed",
}

export enum TravelItineraryEnum {
  MultiTravelItinerary = "MultiTravelItinerary",
  SingleTravelItinerary = "SingleTravelItinerary",
}

export interface TravelportBrand {
  brandId: string;
  brandName?: string;
  brandTier?: number;
}

export interface TripPricingSummary {
  totalPricing: TotalTripPricing;
  pricingByPassenger: PassengerTripPricing[];
}

export type Unknown = PenaltiesInfo | BaggageInfoBase | Penalty;

export interface WebLinks {
  homePage?: string;
  baggageFees?: string;
  wifiFee?: string;
  otherFee?: string;
  seatSelection?: string;
  seatUpgrade?: string;
  manageBooking?: string;
  checkInPolicy?: string;
  changeCancelPolicy?: string;
}

export interface WithFee extends Penalty {
  amount: number;
  currency: string;
}

export const getTripSegmentsFromItinerarySegments = (
  segments: FlightItinerarySegment[],
  airportMap: { [key: string]: Airport | undefined },
  airlineMap: { [key: string]: Airline | undefined },
  useScheduledTimes = false
): TripSegment[] => {
  return segments.map((segment) => {
    const {
      scheduledArrival,
      scheduledDeparture,
      zonedScheduledArrival,
      zonedScheduledDeparture,
      zonedUpdatedArrival,
      zonedUpdatedDeparture,
    } = segment;
    const scheduledArrivalTime = zonedScheduledArrival || scheduledArrival;
    const scheduledDepartureTime =
      zonedScheduledDeparture || scheduledDeparture;
    let arrivalTime, departureTime;

    if (useScheduledTimes) {
      arrivalTime = scheduledArrivalTime;
      departureTime = scheduledDepartureTime;
    } else {
      arrivalTime = zonedUpdatedArrival || scheduledArrivalTime;
      departureTime = zonedUpdatedDeparture || scheduledDepartureTime;
    }

    return {
      arrivalTime,
      departureTime,
      marketingAirline: {
        name: segment.marketingAirline
          ? airlineMap[segment.marketingAirline?.code]?.displayName || ""
          : "",
        code: segment.marketingAirline?.code || "",
      },
      operatingAirline: {
        name: segment.operatingAirline
          ? airlineMap[segment.operatingAirline?.code]?.displayName || ""
          : "",
        code: segment.operatingAirline?.code || "",
      },
      airlineCode: segment.marketingAirline.code,
      airlineName: airlineMap[segment.marketingAirline.code]?.displayName || "",
      flightNumber: `${segment.marketingAirline.flightNumber}`,
      originCode: segment.origin.locationCode,
      originName: airportMap[segment.origin.locationCode]?.name || "",
      destinationCode: segment.destination.locationCode,
      destinationName: airportMap[segment.destination.locationCode]?.name || "",
      stopoverDurationMinutes: segment.stopoverDurationMinutes,
    };
  });
};

export const getPlusDays = (trip: FlightItinerarySlice): number =>
  trip?.segments
    .filter((s) => s.plusDays && s.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);

export const getItinerarySummaryProps = (
  flight: BookedFlightItineraryWithDepartureTime,
  isOutgoing: boolean,
  airportMap: { [key: string]: Airport | undefined },
  airlineMap: { [key: string]: Airline | undefined },
  useScheduledTimes = false
) => {
  const tripSlice = isOutgoing
    ? getDepartureSlice(flight.bookedItinerary)
    : getReturnSlice(flight.bookedItinerary);
  let fareShelf: SegmentShelf | undefined = undefined,
    plusDays = 0;
  let segments: FlightItinerarySegment[] = [];

  if (tripSlice) {
    ({ fareShelf, segments } = tripSlice);
    plusDays = getPlusDays(tripSlice);
  }

  return {
    plusDays,
    tripSlice,
    segments: getTripSegmentsFromItinerarySegments(
      segments,
      airportMap,
      airlineMap,
      useScheduledTimes
    ),
    departureTime:
      segments[0].zonedUpdatedDeparture ||
      segments[0].zonedScheduledDeparture ||
      segments[0].scheduledDeparture ||
      "",
    // TODO find out where to get planeInfo and fareClass info
    planeInfo: "",
    fareClass: fareShelf?.shortBrandName || "",
  };
};

//  TODO show changed itinerary times if a minor schedule change exists
export const getDepartureSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice => {
  if (
    bookedItinerary.travelItinerary.TravelItinerary ===
    TravelItineraryEnum.SingleTravelItinerary
  ) {
    return (bookedItinerary.travelItinerary as unknown as SingleTravelItinerary)
      .slices[0];
  } else {
    return (bookedItinerary.travelItinerary as unknown as MultiTravelItinerary)
      .travelItineraries[0].slices[0];
  }
};

export const getReturnSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice | undefined => {
  let itinerary;
  if (
    bookedItinerary.travelItinerary.TravelItinerary ===
    TravelItineraryEnum.SingleTravelItinerary
  ) {
    itinerary =
      bookedItinerary?.travelItinerary as unknown as SingleTravelItinerary;
    return itinerary.slices.length > 1 ? itinerary.slices[1] : undefined;
  } else {
    itinerary =
      bookedItinerary.travelItinerary as unknown as MultiTravelItinerary;
    return itinerary.travelItineraries.length > 1
      ? itinerary.travelItineraries[1].slices[0]
      : undefined;
  }
};

export const getFlightInfoDetails = (
  flight: BookedFlightItineraryWithDepartureTime,
  formatDate: (date: string) => string,
  airports: { [key: string]: Airport | undefined },
  airlines: { [key: string]: Airline | undefined }
) => {
  const departureSlice = getDepartureSlice(flight.bookedItinerary);
  const returnSlice = getReturnSlice(flight.bookedItinerary);

  // Gets the last segment to avoid using the layover destinationCode
  const departureLastSegmentIndex = departureSlice.segments.length - 1;
  const departureDestinationSegment =
    departureSlice.segments[departureLastSegmentIndex];
  const destinationCode = departureDestinationSegment.destination.locationCode;

  const firstLegDeparture = departureSlice.segments[0];
  const firstLegReturn = returnSlice ? returnSlice.segments[0] : null;
  const originCode = firstLegDeparture.origin.locationCode;
  const airlineCode = firstLegDeparture.marketingAirline.code;

  return {
    origin: airports[originCode]?.cityName || originCode,
    destination: airports[destinationCode]?.cityName || destinationCode,
    confirmationCode:
      flight.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ??
      "",
    startDate: formatDate(firstLegDeparture.scheduledDeparture),
    endDate: firstLegReturn
      ? formatDate(firstLegReturn.scheduledDeparture)
      : "",
    roundTrip: returnSlice ? true : false,
    airlineCode,
    airlineName:
      airlines[firstLegDeparture.marketingAirline.code]?.displayName ||
      airlineCode,
    flightNumber:
      airlineCode +
        " " +
        firstLegDeparture.marketingAirline.flightNumber.toString() ?? "",
  };
};

export const getRestrictionInfo = (
  flight: BookedFlightItineraryWithDepartureTime,
  translate: TFunction,
  formatFiatPrice: (price: Omit<FiatPrice, "currencySymbol">) => string,
  cfarSelected?: boolean | undefined,
  chfarSelected?: boolean | undefined
): Restriction[] => {
  const restrictions: Restriction[] = [];
  const restrictionInfo = flight.bookedItinerary.restrictions;
  switch (restrictionInfo?.baggageInfo.BaggageInfo) {
    case BaggageInfoEnum.Allowed: {
      const info = restrictionInfo.baggageInfo;
      if (info.allowance.descriptions.length > 0) {
        restrictions.push({
          name: "",
          description: info.allowance.descriptions.join(" "),
          symbol: AirRestrictionStatus.INCLUDED,
        });
      }
      if (info.charges.length > 0) {
        restrictions.push({
          name: translate("bagAllowance.title"),
          symbol: AirRestrictionStatus.PURCHASABLE,
          description: info.charges
            .map((curr) => {
              return translate("bagAllowance.alloweAt", {
                bagNumber: curr.firstPiece,
                price: formatFiatPrice({
                  value: curr.amount,
                  currencyCode: curr.currency,
                }),
              });
            })
            .join(", "),
        });
      }
      break;
    }
    case BaggageInfoEnum.NotAllowed:
      restrictions.push({
        name: translate("bagAllowance.title"),
        symbol: AirRestrictionStatus.PURCHASABLE,
        description: translate("bagAllowance.notAllowed"),
      });
      break;
    case BaggageInfoEnum.Unknown:
      break;
    default:
      break;
  }
  switch (restrictionInfo?.penaltiesInfo.PenaltiesInfo) {
    case PenaltiesInfoEnum.Known: {
      const info = restrictionInfo?.penaltiesInfo as Known;
      restrictions.push(
        getPenaltyInfo(
          info.cancelPenalty,
          translate("cancellationPolicy.title"),
          translate,
          formatFiatPrice,
          cfarSelected
        )
      );
      restrictions.push(
        getPenaltyInfo(
          info.changePenalty,
          translate("exchangePolicy.title"),
          translate,
          formatFiatPrice,
          undefined,
          chfarSelected
        )
      );
      break;
    }
    case PenaltiesInfoEnum.ExternallyManaged:
    case PenaltiesInfoEnum.Unknown:
      restrictions.push({
        name: translate("cancellationPolicy.title"),
        description: translate("cancellationPolicy.contactAirline"),
        symbol: AirRestrictionStatus.UNKNOWN,
      });
      break;
    default:
      break;
  }
  return restrictions;
};

const getPenaltyInfo = (
  penalty: Penalty,
  name: string,
  translate: TFunction,
  formatFiatPrice: (price: Omit<FiatPrice, "currencySymbol">) => string,
  cfarSelected?: boolean | undefined,
  chfarSelected?: boolean | undefined
): Restriction => {
  if (cfarSelected) {
    return {
      name,
      description: translate("restrictionsCfarText"),
      symbol: AirRestrictionStatus.INCLUDED,
    };
  } else if (chfarSelected) {
    return {
      name: translate("exchangePolicy.chfarTitle"),
      description: translate("restrictionsChfarText"),
      symbol: AirRestrictionStatus.INCLUDED,
    };
  }
  switch (penalty.Penalty) {
    case PenaltyEnum.WithFee: {
      const info = penalty as WithFee;
      return {
        name,
        description: translate("exchangePolicy.forAFee", {
          fee: formatFiatPrice({
            value: info.amount,
            currencyCode: info.currency,
          }),
        }),
        symbol: AirRestrictionStatus.PURCHASABLE,
      };
    }
    case PenaltyEnum.Unknown:
      return {
        name,
        description: translate("contactSupport"),
        symbol: AirRestrictionStatus.UNAVAILABLE,
      };
    case PenaltyEnum.NotPossible:
      return {
        name,
        description: translate("exchangePolicy.nonRefundable"),
        symbol: AirRestrictionStatus.UNAVAILABLE,
      };
    case PenaltyEnum.NoPenalty:
      return {
        name,
        description: translate("exchangePolicy.free"),
        symbol: AirRestrictionStatus.PURCHASABLE,
      };
  }
};

export const getDurationFromSegments = (
  segments: FlightItinerarySegment[],
  getTimeDiff: (departure: string, arrival: string) => number
): number => {
  const departureSeg = segments[0];
  const arrivalSeg = segments[segments.length - 1];
  const departureTime =
    departureSeg.zonedUpdatedDeparture ||
    departureSeg.zonedScheduledDeparture ||
    departureSeg.scheduledDeparture;
  const arrivalTime =
    arrivalSeg.zonedUpdatedArrival ||
    arrivalSeg.zonedScheduledArrival ||
    arrivalSeg.scheduledArrival;
  return getTimeDiff(departureTime, arrivalTime);
};

export const getStopsFromSegments = (
  segments: FlightItinerarySegment[] = []
) => {
  return segments.length ? segments.length - 1 : 0;
};

export const getCurrencyPrefix = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const { sellingPricing } = flight.bookedItinerary;
  return `${sellingPricing.totalPricing.currency} ${sellingPricing.totalPricing.baseWithoutMargin.fiat.currencySymbol}`;
};

export const getPriceWithDecimal = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const { total } = flight.bookedItinerary.sellingPricing.totalPricing;
  return total.fiat.value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const getFlightSummaryInfo = (
  isDeparture: boolean,
  flight: BookedFlightItineraryWithDepartureTime,
  airportMap: { [key: string]: Airport | undefined },
  airlineMap: { [key: string]: Airline | undefined }
): FlightSummaryInfoWithKeys | null => {
  if (flight) {
    const slice = isDeparture
      ? getDepartureSlice(flight?.bookedItinerary)
      : getReturnSlice(flight?.bookedItinerary);
    const departureSegment = slice?.segments.at(0);
    const arrivalSegment = slice?.segments.at(-1);
    const departure = departureSegment?.zonedScheduledDeparture;
    const arrival = arrivalSegment?.zonedScheduledArrival;
    const originLocationCode = departureSegment?.origin.locationCode;
    const destinationLocationCode = arrivalSegment?.destination.locationCode;
    const stops = slice?.segments?.length ? slice?.segments?.length - 1 : 0;

    return {
      originCity: airportMap?.[originLocationCode || ""]?.cityName,
      destinationCity: airportMap?.[destinationLocationCode || ""]?.cityName,
      departure: departure || "",
      arrival: arrival || "",
      airlineName:
        airlineMap[departureSegment?.marketingAirline?.code || ""]
          ?.displayName || "",
      airlineCode: departureSegment?.marketingAirline?.code || "",
      isDeparture,
      titleKeys: {
        origin: airportMap?.[originLocationCode || ""]?.cityName,
        destination: airportMap?.[destinationLocationCode || ""]?.cityName,
        originLocationCode,
        destinationLocationCode,
      },
      stops,
    };
  }
  return null;
};

export const getSliceSummaryInfo = (
  isDeparture: boolean,
  segment: FlightItinerarySegment,
  airportMap: { [key: string]: Airport | undefined },
  airlineMap: { [key: string]: Airline | undefined }
): FlightSummaryInfoWithKeys | null => {
  if (segment) {
    const departure =
      segment.zonedScheduledDeparture || segment.scheduledDeparture;
    const arrival = segment.zonedScheduledArrival || segment.scheduledArrival;
    const originLocationCode = segment.origin.locationCode;
    const destinationLocationCode = segment.destination.locationCode;
    return {
      originCity: airportMap?.[originLocationCode || ""]?.cityName,
      destinationCity: airportMap?.[destinationLocationCode || ""]?.cityName,
      departure: departure || "",
      arrival: arrival || "",
      airlineName:
        airlineMap[segment?.marketingAirline?.code || ""]?.displayName || "",
      airlineCode: segment?.marketingAirline?.code || "",
      isDeparture,
      titleKeys: {
        origin: airportMap?.[originLocationCode || ""]?.cityName,
        destination: airportMap?.[destinationLocationCode || ""]?.cityName,
      },
    };
  }
  return null;
};

export enum BookingType {
  Flight = "Flight",
  Ground = "Ground",
  Lodging = "Lodging",
}

export interface ResendConfirmationProduct {
  type: BookingType;
  value: Uuid | { value: Uuid };
}

export interface ResendConfirmationReq {
  /** Recipient's email address */
  emailAddress: string;
  emailType: EmailType;
  product: ResendConfirmationProduct;
}

export type MaybeFlightItinerary =
  BookedFlightItineraryWithDepartureTime | null;

export type FlightSummaryInfo = {
  originCity: string | undefined;
  destinationCity: string | undefined;
  departure: string;
  arrival: string;
  airlineCode: string;
  airlineName: string;
  isDeparture: boolean;
  title: string;
  stops?: number;
};

export type FlightSummaryInfoWithKeys = Omit<FlightSummaryInfo, "title"> & {
  titleKeys: {
    [key: string]: string | undefined;
  };
};

export enum EmailType {
  FlightConfirmation = "flight_ticketed",
  FlightWhatsNext = "flight_whats_next",
  HotelConfirmation = "hotel_booking_confirmation",
}

export enum CfarExerciseProgress {
  NotStarted,
  Loading,
  Review,
  Pending,
  Completed,
}

export enum ChfarExerciseProgress {
  NotStarted,
  Loading,
  Started,
  FlightRebookSelect,
  FlightCalendar,
  FlightShopList,
  FlightReview,
  FlightConfirmation,
  Pending,
  Completed,
}

export enum DisruptionExerciseProgress {
  NotStarted,
  LandingPage,
  RebookSelected,
  RefundSelected,
  FlightSelect,
  FlightSearch,
  Review,
  ConfirmationPage,
  Pending,
  Completed,
  RefundReview,
  RefundConfirmation,
  RebookReview,
  RebookConfirmation,
  ContactInfo,
}

export enum DisruptionExerciseProductType {
  ScheduleChange,
  MissedConnection,
}
