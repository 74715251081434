import { HeaderNavigationMenuItemType } from "@hopper-b2b/hopper-utils";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CurrencySelectRadio } from "../../../Currency";
import { Link } from "react-router-dom-v5-compat";
import { HELP_CENTER_FAQS_LINK_PATH } from "../../../FaqButtonLinks";
import { IconComponent } from "../../../IconComponent";
import { IconName } from "../../../Icon";
import { PATH_FLY_FLAIR, PATH_TERMS_HTS } from "../../../../utils/constants";

import styles from "./HeaderMenu.module.scss";
import { trackEvent } from "@hopper-b2b/api";
import { TAPPED_LINK, TappedLinkProperties } from "@hopper-b2b/types";
import { LanguageSelectRadio } from "@virtual-interline/ui";

export const HEADER_MENU_POPPER_ID = "header-menu-popper";

export type HeaderMenuItemType = HeaderNavigationMenuItemType & {
  disabled?: boolean;
  children?: (HeaderNavigationMenuItemType | ReactNode)[];
};

export type HeaderMenuProps = {
  open: boolean;
  anchorEl: HTMLElement;
  onClose: (e?: Event) => void;
};

export const HeaderMenu = ({ open, anchorEl, onClose }: HeaderMenuProps) => {
  const id = open ? HEADER_MENU_POPPER_ID : undefined;
  const {
    t,
    brand: {
      urls: { helpCenterBaseUrl },
    },
  } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const links = [
    {
      text: [t("visitFaq")],
      urlLink: `${helpCenterBaseUrl}${HELP_CENTER_FAQS_LINK_PATH}`,
    },
    { text: [t("termsAndConditions")], urlLink: PATH_TERMS_HTS },
    { text: [t("flyFlair")], urlLink: PATH_FLY_FLAIR },
  ];

  return (
    <WrapperComponent
      id={id}
      isOpen={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <Fade in={open}>
        <Paper
          className={clsx(styles.paper, {
            [styles.mobilePaper]: matchesMobile,
          })}
        >
          <Box>
            <LanguageSelectRadio />
            <Divider style={{ margin: "1rem 0" }} />
            <CurrencySelectRadio />
            <Divider style={{ margin: "1rem 0" }} />
            <Box className={styles.linksContainer}>
              <legend>{t("haveQuestions")}</legend>
              {links.map((link) => (
                <Link
                  key={link.urlLink}
                  to={link.urlLink}
                  target="_blank"
                  rel="noopener"
                  onClick={() => {
                    trackEvent({
                      eventName: TAPPED_LINK,
                      properties: {
                        current_url: window.location.origin,
                        link_href: link.urlLink,
                        element_entry_point: "mobile_header",
                      } as TappedLinkProperties,
                    });
                  }}
                >
                  <Typography className={styles.link} variant="body2">
                    <span>{link.text}</span>
                    <IconComponent name={IconName.ExternalLink} />
                  </Typography>
                </Link>
              ))}
            </Box>
          </Box>
        </Paper>
      </Fade>
    </WrapperComponent>
  );
};

type WrapperComponentProps = {
  id: string;
  isOpen: boolean;
  anchorEl: HTMLElement;
  onClose: () => void;
};

const WrapperComponent = ({
  id,
  isOpen,
  anchorEl,
  children,
  onClose,
}: PropsWithChildren<WrapperComponentProps>) => {
  const { matchesMobile } = useDeviceTypes();

  const menuContent = isOpen ? (
    <ClickAwayListener onClickAway={onClose}>{children}</ClickAwayListener>
  ) : (
    children
  );

  return matchesMobile && isOpen ? (
    <Box className={styles.mobileWrapper} zIndex="tooltip">
      {menuContent}
    </Box>
  ) : (
    <Popper
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      disablePortal={true}
      keepMounted={true}
      placement={matchesMobile ? "bottom-start" : "bottom-end"}
    >
      {menuContent}
    </Popper>
  );
};
