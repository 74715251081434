import { createContext, useContext } from "react";

export type CommonFeatureFlagsContextProps = {
  enableApplePay?: boolean;
  enableGooglePay?: boolean;

  // Fintech Flags
  enablePriceWatch?: boolean;
  enablePriceFreeze?: boolean;
  enablePricePrediction?: boolean;
  enableScheduleChange?: boolean;
  enableMissedConnection?: boolean;
  enableCfar?: boolean;
  enableChfar?: boolean;
  enableVipSupport?: boolean;
  enableDisruptionExerciseRebooking?: boolean;
  enableDisruptionProtection?: boolean;
  enableCoreDisruptionProtection?: boolean; // Flag to enable disruption product from core-disruption
  enableDisruptionProtectionUniversalExercise?: boolean;
  enablePriceDropProtection?: boolean;
  enablePriceDrop?: boolean;
  hideCfarHackerFare?: boolean;
  hideChfarHackerFare?: boolean;
  hideDisruptionHackerFare?: boolean;

  // Features
  enableDarkMode?: boolean;
  enableLoadingSteps?: boolean;
  enableRetainFilters?: boolean;

  // Growth
  enableWallet?: boolean;
  enableWalletOffers?: boolean;
  enableWalletCredits?: boolean;

  enableSavePayment?: boolean;
  hideEmailOnContactInfoScreen?: boolean;
  showContactInfoInTravelerSelection?: boolean;
  // Experiment to replace Material UI Components
  replaceMaterialUI?: boolean;
};

export type AirFeatureFlagsContextProps = CommonFeatureFlagsContextProps & {
  // CX Product
  enableAirExchange?: boolean;
  enableHFv2?: boolean;

  enableAirShopV4?: boolean;
  enableB6Upsell?: boolean;
  enableIataLookup?: boolean;

  // Experiments
  hideFareDetails?: boolean;
  hideSeats?: boolean;
  hideAirItineraryReview?: boolean;
  enableFintechSelection?: boolean;
  hideContactInfoScreen?: boolean;
  hideAirPriceFreezeHackerFare?: boolean;

  // Virtual Interline
  enableMergeSortingDrodpdownWithFilters?: boolean;
  enableRemoveRecommendSort?: boolean;
  /** experiment to show only one fare and change text within the shop/book flow */
  enableDisplayBasicFareOnly?: boolean;
  /** experiment to tag flights without using algomerch, and move it to the top of the list */
  enableTopFlights?: boolean;
  /** experiment to fare select flight shop row selection instead of expanding fare details*/
  enableSingleTapSelectFlight?: boolean;
};

export type LodgingFeatureFlagsContextProps = CommonFeatureFlagsContextProps & {
  enableLodging?: boolean;
  enableLodgingCfar?: boolean;
  enableLodgingRefundableRooms?: boolean;
  enableLodgingRefundableRoomsOrchestratorFlowWithKOandUpdatedCopy?: boolean;
  enableLodgingSelfServe?: boolean;
  enableLodgingPriceChangeBanner?: boolean;
  hideLodgingMap?: boolean;

  enableHotelPriceFreeze?: boolean;

  // Features
  showLodgingRooms?: boolean;
  showHorizontalScrollListInMobileMap?: boolean;
  renderLodgingStrikethroughPrice?: boolean;
};

export type TripsFeatureFlagsContextProps = CommonFeatureFlagsContextProps & {
  enableAirTripSearch?: boolean;
};

export type FeatureFlagsContextProps = AirFeatureFlagsContextProps &
  LodgingFeatureFlagsContextProps &
  TripsFeatureFlagsContextProps;

export const FeatureFlagsContext = createContext<FeatureFlagsContextProps>({});

export const useFeatureFlagsContext = (): FeatureFlagsContextProps => {
  const ctx = useContext(FeatureFlagsContext);
  if (!ctx) {
    console.warn(`Must be used within a FeatureFlagsContext`);
  }

  return ctx as FeatureFlagsContextProps;
};

export const useAirFeatureFlagsContext = (): AirFeatureFlagsContextProps => {
  return useFeatureFlagsContext() as AirFeatureFlagsContextProps;
};
export const useLodgingFeatureFlagsContext =
  (): LodgingFeatureFlagsContextProps => {
    return useFeatureFlagsContext() as LodgingFeatureFlagsContextProps;
  };
export const useTripsFeatureFlagsContext =
  (): TripsFeatureFlagsContextProps => {
    return useFeatureFlagsContext() as TripsFeatureFlagsContextProps;
  };

// Fintech Flags

export const useEnablePriceWatch = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceWatch;
};

export const useEnableAirTripSearch = () => {
  const featureFlagsContext = useTripsFeatureFlagsContext();
  return featureFlagsContext?.enableAirTripSearch;
};

export const useEnablePriceFreeze = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceFreeze;
};

export const useEnablePricePrediction = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePricePrediction;
};

export const useEnableScheduleChange = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableScheduleChange;
};

export const useEnableMissedConnection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableMissedConnection;
};

export const useEnableCfar = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableCfar;
};

export const useEnableRefundableRooms = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingRefundableRooms;
};

export const useEnableLodgingRefundableRoomsOrchestratorFlowWithKOandUpdatedCopy =
  () => {
    const featureFlagsContext = useFeatureFlagsContext();
    return featureFlagsContext?.enableLodgingRefundableRoomsOrchestratorFlowWithKOandUpdatedCopy;
  };

export const useEnableChfar = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableChfar;
};

export const useEnableWallet = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableWallet;
};

export const useEnableWalletOffers = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableWalletOffers;
};

export const useEnableWalletCredits = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableWalletCredits;
};

export const useEnableVipSupport = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableVipSupport;
};

export const useEnableDisruptionExerciseRebooking = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionExerciseRebooking;
};

export const useEnableDisruptionProtection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionProtection;
};

export const useEnableCoreDisruptionProtection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableCoreDisruptionProtection;
};

export const useEnableDisruptionProtectionUniversalExercise = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionProtectionUniversalExercise;
};

export const useEnableCfarHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideCfarHackerFare;
};

export const useEnableChfarHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideChfarHackerFare;
};

export const useEnableDisruptionHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideDisruptionHackerFare;
};

export const useEnablePriceDropProtection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceDropProtection;
};

// Features

export const useEnableDarkMode = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDarkMode;
};

export const useEnableLoadingSteps = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLoadingSteps;
};

export const useEnableAirShopV4 = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableAirShopV4;
};

export const useEnableB6Upsell = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableB6Upsell;
};

// Virtual Interline
export const useEnableMergeSortingDrodpdownWithFilters = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableMergeSortingDrodpdownWithFilters;
};

export const useEnableRemoveRecommendSort = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableRemoveRecommendSort;
};

export const useEnableTopFlights = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableTopFlights;
};

export const useEnableDisplayBasicFareOnly = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableDisplayBasicFareOnly;
};

export const useEnableSingleTapSelectFlight = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableSingleTapSelectFlight;
};

// Experiments

export const useHideFareDetails = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideFareDetails;
};

export const useHideSeats = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideSeats;
};

export const useHideReviewItitinerary = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideAirItineraryReview;
};

export const useEnableAirExchange = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableAirExchange;
};

export const useEnableFintechSelection = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableFintechSelection;
};

export const useEnableHFv2 = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableHFv2;
};

export const useHideContactInfoScreen = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideContactInfoScreen;
};

export const useHideEmailAddressOnContactInfo = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideEmailOnContactInfoScreen;
};

export const useShowContactInfoInTravelerSelection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.showContactInfoInTravelerSelection;
};

export const useEnableLodgingCfar = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingCfar;
};

export const useEnableLodgingSelfServe = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingSelfServe;
};

export const useEnableSavePayment = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableSavePayment;
};

export const useEnableIataLookup = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableIataLookup;
};

export const useHideAirPriceFreezeHackerFare = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideAirPriceFreezeHackerFare;
};

export const useEnableLodgingPriceChangeBanner = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingPriceChangeBanner;
};

export const useEnableApplePay = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableApplePay;
};

export const useEnableGooglePay = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableGooglePay;
};

export const useEnableLodging = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodging;
};

export const useEnableRetainFilters = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableRetainFilters;
};

export const useHideLodgingMap = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideLodgingMap;
};

export const useReplaceMaterialUI = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.replaceMaterialUI;
};

export const useRenderLodgingStrikethroughPrice = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.renderLodgingStrikethroughPrice;
};
