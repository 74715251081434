import { PATH_HOME } from "@hopper-b2b/common-utils";
import { Currency, TranslationLanguage } from "@hopper-b2b/types";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";

export const B2B_LOGO_ALT = "B2B Logo";
export const HOPPER_LOGO_ALT = "HTS Logo";

export const DEFAULT_LANG = TranslationLanguage.en;
export const DEFAULT_CURRENCY = Currency.CAD;

export const SESSION_ID_KEY = "sessionId";

export const PATH_TERMS = `${PATH_HOME}terms`;
export const PATH_TERMS_HTS = `${PATH_TERMS}/hts-connect-services`;
export const PATH_TERMS_MCP = `${PATH_TERMS}/hts-missed-connection-guarantee`;
export const PATH_FLY_FLAIR = `https://flyflair.com/`;

export const DATE_FORMAT_HOUR = DateTimeFormatStyle.Custom({
  hour: "2-digit",
  minute: "numeric",
});

export const DATE_FORMAT_DAYS = DateTimeFormatStyle.Custom({
  weekday: "long",
  month: "short",
  day: "numeric",
});
