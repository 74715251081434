import { assign } from "xstate";
import { ParentState } from "../../../index";
import { getObjectKeysAsObject, setContextWithKey } from "../../../helpers";
import type { HotelsPriceFreezeMachineContext } from "./types";
import type { UpdateSelectedHotelsPriceFreezeOffer } from "./events";

export const actions = {
  updateSelectedPriceFreezeOffer: assign(
    (
      context: HotelsPriceFreezeMachineContext,
      event: UpdateSelectedHotelsPriceFreezeOffer
    ) => {
      const currentSelectedOffer = context.priceFreeze.selectedOffer;
      const selectedOffer = context.priceFreeze.priceFreezeOffers.find(
        (offer) =>
          offer.serviceDetails.serviceDurationDays ===
          event.selectedOfferDuration
      );
      return setContextWithKey(
        context,
        `${ParentState.priceFreeze}.selectedOffer`,
        selectedOffer ? selectedOffer : currentSelectedOffer
      );
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
