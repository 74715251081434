import { Fragment } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Footer as GenericFooter } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { ButtonWrap } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import HTSPoweredByLogo from "../../assets/b2b/b2b-logo-powered-by.svg";
import { LEGAL } from "./constants";
import "./styles.scss";
import { PATH_FLY_FLAIR, PATH_TERMS_HTS } from "../../utils/constants";
import { HELP_CENTER_FAQS_LINK_PATH } from "../FaqButtonLinks";
import { trackEvent } from "@hopper-b2b/api";
import { TAPPED_LINK, TappedLinkProperties } from "@hopper-b2b/types";

export const Footer = () => {
  const { matchesMobile } = useDeviceTypes();
  const {
    t,
    brand: {
      urls: { helpCenterBaseUrl },
    },
  } = useI18nContext();

  const renderLinks = () => {
    const renderLink = (texts: string[], urlLink: string) => (
      <ButtonWrap
        onClick={() => {
          trackEvent({
            eventName: TAPPED_LINK,
            properties: {
              current_url: window.location.origin,
              link_href: urlLink,
              element_entry_point: "footer",
            } as TappedLinkProperties,
          });
          window.open(urlLink, "_blank");
        }}
      >
        <Box className="button-text-wrapper">
          {texts.map((text, index) => (
            <Typography key={index} className="button-text" variant="subtitle2">
              {text}
            </Typography>
          ))}
        </Box>
      </ButtonWrap>
    );

    const links = [
      {
        texts: [t("faq")],
        urlLink: `${helpCenterBaseUrl}${HELP_CENTER_FAQS_LINK_PATH}`,
      },
      { texts: [t("termsAndConditions")], urlLink: PATH_TERMS_HTS },
      { texts: [t("flyFlair")], urlLink: PATH_FLY_FLAIR },
    ];

    const renderSeparator = (index: number) => {
      if (index < links.length - 1) {
        return <Divider className="divider" orientation="vertical" />;
      }
    };

    return (
      <Box className="links-wrapper">
        {links.map((link, index) => (
          <Fragment key={index}>
            {renderLink(link.texts, link.urlLink)}
            {renderSeparator(index)}
          </Fragment>
        ))}
      </Box>
    );
  };

  return (
    <GenericFooter
      className={clsx("generic-footer")}
      isMobile={matchesMobile}
      left={<p>{LEGAL}</p>}
      right={
        <ButtonWrap className="hopper-icon-wrapper">
          <img className="hopper-icon" src={HTSPoweredByLogo} alt="HTS logo" />
        </ButtonWrap>
      }
      {...(!matchesMobile && { center: renderLinks() })}
    />
  );
};

export default Footer;
