import { State } from "xstate";
import {
  BookedFlightItinerary,
  MultiTicketType,
  OrderedFlight,
  PassengersForFareRequest,
  QuotedFlight,
} from "@b2bportal/air-booking-api";
import { Product } from "@b2bportal/purchase-api";
import { ParentState } from "../../../types/common";
import { FlightContext } from "./types";
import dayjs from "dayjs";
import {
  ChooseTravelerProperties,
  Currency,
  getHopperFareRatingName,
  RegionType,
  TripCategory,
} from "@hopper-b2b/types";
import { FlightPassengerSelectors } from "./states/PassengerInformation";
import { getAllSelectedUserPassengersParent } from "./states/PassengerInformation/selectors";
import {
  exhaustiveTypeCheck,
  getVirtualInterlineLayovers,
} from "@hopper-b2b/utilities";

type FlightStateType = State<FlightContext>;
type FlightStateWithoutValue = Pick<FlightStateType, "context">;
type FlightStateWithAndWithoutValue = FlightStateType | FlightStateWithoutValue;

export const getFinalizedItinerary = (state: FlightStateWithoutValue) => {
  const products = state.context[ParentState.cartFulfill].fulfilledProducts;
  const orderedFlight = products?.find(
    (p) => p.type === Product.MultiProviderFlight || p.type === Product.Flight
  )?.value as OrderedFlight;
  return orderedFlight?.travelItinerary;
};

export const getAgentLocator = (
  state: FlightStateWithoutValue | FlightStateWithoutValue
): string | undefined => {
  const flight: BookedFlightItinerary = state.context[
    ParentState.cartFulfill
  ].fulfilledProducts?.find(
    (product) =>
      product.type === Product.Flight ||
      product.type === Product.MultiProviderFlight
  )?.value;
  return flight?.travelItinerary?.locators?.agent?.value || "";
};

export const getQuotedFlightProduct = (
  state: FlightStateWithoutValue | FlightStateWithoutValue
): QuotedFlight | undefined => {
  const breakdown = state.context[ParentState.cartQuote].quoteBreakdown;
  const flightProduct = breakdown?.products.find(
    (p) =>
      p.product.type === Product.Flight ||
      p.product.type === Product.MultiProviderFlight
  );
  return flightProduct?.product?.value;
};

export const getQuoteBreakdownFlightPricing = (
  state: FlightStateWithoutValue | FlightStateWithoutValue
) => {
  const quotedFlight = getQuotedFlightProduct(state);
  return quotedFlight?.pricingSummary;
};

export const mergePassengersAndBreakdownPersons = (
  state: FlightStateWithoutValue
) => {
  const selectedPassengers =
    FlightPassengerSelectors.getAllSelectedUserPassengersParent(state);
  const pricingSummary = getQuoteBreakdownFlightPricing(state);
  const personWithPassengerType = pricingSummary?.pricingByPassenger.reduce(
    (allPax, pax) => ({ ...allPax, [pax.person.id]: pax.passengerType }),
    {} as Record<string, string>
  );

  return selectedPassengers.map((passenger) => {
    const passengerType = personWithPassengerType?.[passenger.id];
    if (passengerType) {
      return { ...passenger, passengerType };
    }
    return passenger;
  });
};

export const getQuotedShoppedTripId = (state: FlightStateWithoutValue) => {
  const flightProduct = getQuotedFlightProduct(state);
  return flightProduct?.shoppedTripId;
};

export const getQuoteBookingSessionId = (state: FlightStateWithoutValue) => {
  const quotedFlight = getQuotedFlightProduct(state);
  return quotedFlight?.bookingSessionId;
};

export const getQuotedFlightTrackingProperties = (
  state: FlightStateWithoutValue
) => {
  const quotedFlight = getQuotedFlightProduct(state);
  return quotedFlight?.trackingProperties?.properties || {};
};

export const getSelectedTripParent = (state: FlightStateWithAndWithoutValue) =>
  state.context.flightShop.selectedTrip;

export const getSelectedTripDetailsParent = (
  state: FlightStateWithAndWithoutValue
) => state.context?.flightShop.tripDetails;

export const getTripCategoryParent = (state: FlightStateWithAndWithoutValue) =>
  state.context.flightSearch.tripCategory;

export const getFlightSearchDestination = ({
  context,
}: FlightStateWithAndWithoutValue) => context.flightSearch.destination;

export const getFlightSearchOrigin = ({
  context,
}: FlightStateWithAndWithoutValue) => context.flightSearch.origin;

export const getAirportMap = (state: FlightStateWithAndWithoutValue) =>
  state.context.flightShop.airports;

export const getAirlineMap = (state: FlightStateWithAndWithoutValue) =>
  state.context.flightShop.airlines;

export const getDestinationCityName = (
  state: FlightStateWithAndWithoutValue
) => {
  const airports = getAirportMap(state);
  const destination = getFlightSearchDestination(state);
  return airports[destination?.id.code.code]?.cityName || destination?.label;
};

export const getOriginCityName = (state: FlightStateWithAndWithoutValue) => {
  const airports = getAirportMap(state);
  const origin = getFlightSearchOrigin(state);
  return airports[origin?.id.code.code]?.cityName || origin?.label;
};

export const getOriginCountryCode = ({
  context,
}: FlightStateWithAndWithoutValue) => context.flightSearch.originCountryCode;

export const getDestinationCountryCode = ({
  context,
}: FlightStateWithAndWithoutValue) =>
  context.flightSearch.destinationCountryCode;

export const getFlightSearchDepartureDate = ({
  context,
}: FlightStateWithAndWithoutValue) => context.flightSearch.departureDate;

export const getFlightSearchReturnDate = ({
  context,
}: FlightStateWithAndWithoutValue) => context.flightSearch.returnDate;

export const getTripDetails = ({ context }: FlightStateWithAndWithoutValue) =>
  context.flightShop.tripDetails;

export const getChooseTravelerProperties = ({
  context,
}: FlightStateWithAndWithoutValue): ChooseTravelerProperties => {
  const tripCategory = getTripCategoryParent({ context }) as TripCategory;
  const destination = getFlightSearchDestination({ context });
  const origin = getFlightSearchOrigin({ context });
  const originCountryCode = getOriginCountryCode({ context });
  const destinationCountryCode = getDestinationCountryCode({ context });
  const departureDate = getFlightSearchDepartureDate({ context });
  const returnDate = getFlightSearchReturnDate({ context });
  const formattedDepartureDate = dayjs(departureDate).format("YYYY-MM-DD");
  const formattedReturnDate =
    returnDate && dayjs(returnDate).format("YYYY-MM-DD");

  const selectedTripDetails = getTripDetails({ context });

  return {
    departure_date: formattedDepartureDate,
    destination_country_code: destinationCountryCode,
    destination: destination
      ? `${destination?.id.code.regionType}/${destination?.id.code.code}`
      : `${RegionType.Airport}/${selectedTripDetails.slices[0].destinationCode}`,
    origin_country_code: originCountryCode,
    origin: origin
      ? `${origin?.id.code.regionType}/${origin?.id.code.code}`
      : `${RegionType.Airport}/${selectedTripDetails.slices[0].originCode}`,
    return_date: formattedReturnDate ? formattedReturnDate : undefined,
    trip_type: tripCategory,
  };
};

export const getPricing = (state: FlightStateWithAndWithoutValue) => {
  const tripPricing = state.context.common.tripPricing;
  const quoteTripPricing = getQuoteBreakdownFlightPricing(state);
  const validPriceQuote =
    (quoteTripPricing?.pricingByPassenger?.length || 0) > 0;
  return validPriceQuote ? quoteTripPricing : tripPricing;
};

export const getShopPricingInfo = ({
  context,
}: FlightStateWithAndWithoutValue) => context.flightShop.shopPricingInfo;

export const getCurrencyFromPricingInfo = (
  state: FlightStateWithAndWithoutValue
) => {
  const shopPricingInfo = getShopPricingInfo(state);
  return {
    currencyCode: shopPricingInfo.fare?.[0]?.pricing.baseAmount.fiat
      .currencyCode as Currency,
    currencySymbol:
      shopPricingInfo.fare?.[0]?.pricing.baseAmount.fiat.currencySymbol,
  };
};

export const getTripPricing = (state: FlightStateWithAndWithoutValue) =>
  state.context.common.tripPricing;

export const getFlightSearchDepartureLabel = (
  state: FlightStateWithAndWithoutValue
) => state.context.flightShop.departureLabel;

export const getFlightSearchReturnLabel = (
  state: FlightStateWithAndWithoutValue
) => state.context.flightShop.returnLabel;

export const getSelectedTripId = (
  state: FlightStateWithAndWithoutValue
): string => state.context.flightShop.selectedTrip.tripId || "";

export const getSelectedFareId = (
  state: FlightStateWithAndWithoutValue
): string =>
  state.context.flightShop.selectedTrip.returnFareId ||
  state.context.flightShop.selectedTrip.outgoingFareId ||
  "";

export const getSelectedFareDetails = (
  state: FlightStateWithAndWithoutValue
) => {
  const tripDetails = getSelectedTripDetailsParent(state);
  const selectedFareId = getSelectedFareId(state);
  return tripDetails?.fareDetails.find((f) => f.id === selectedFareId);
};

export const getIsSelectedTripOneWay = (
  state: FlightStateWithAndWithoutValue
) =>
  !state.context.flightShop.selectedTrip.returnSliceId &&
  !state.context.flightShop.selectedTrip.returnFareId;

export const getClientAssets = ({ context }: FlightStateWithAndWithoutValue) =>
  context.clientAssets;

export const getTripPriceParamsParent = (
  state: FlightStateWithAndWithoutValue
): PassengersForFareRequest => {
  const selectedTrip = getSelectedTripParent(state);
  const selectedPassengerIds =
    FlightPassengerSelectors.getSelectedPassengerIdsParent(state);
  const selectedLapInfants =
    FlightPassengerSelectors.getSelectedLapInfantIdsParent(state);

  const fareId = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId || "";
  const defaultPricingParams = {
    tripId: selectedTrip.tripId || "",
    seatedPassengers: selectedPassengerIds,
    lapInfants: selectedLapInfants,
    fareId: fareId,
    // TODO: Add ancilliary ids here
    ancillaryIds: [],
  };

  return defaultPricingParams;
};

/**
 * HFv1 needs to be a Flight product not a MultiProviderFlight
 * HFv2 need to be a MultiProviderFlight
 * both share the MultiTicketType "hackerFare", this is kind of hacky but this accounts for it
 *
 * @param multiTicketType
 * @param HFv2
 */
export const getProductType = (
  multiTicketType: MultiTicketType,
  HFv2: boolean
) => {
  switch (multiTicketType) {
    case "single":
      return Product.Flight;
    case "virtualInterline":
      return Product.MultiProviderFlight;
    case "hackerFare":
      return HFv2 ? Product.MultiProviderFlight : Product.Flight;
    default:
      exhaustiveTypeCheck(multiTicketType);
  }
};

export const getFlightMultiTicketType = (
  state: FlightStateWithAndWithoutValue
): MultiTicketType => {
  const { multiTicketType } = getSelectedFareDetails(state);
  if (multiTicketType) {
    return multiTicketType;
  }

  // multiTicketType is undefined, check if the trip is one way - if so it's single
  // TODO: Update to support VI
  const isTripOneWay = getIsSelectedTripOneWay(state);
  if (isTripOneWay) {
    return MultiTicketType.single;
  }

  // It's a round trip, check if the airlines are the same or not
  const selectedTripDetails = getSelectedTripDetailsParent(state);
  const outboundSlice = selectedTripDetails.slices[0];
  const returnSlice = selectedTripDetails.slices[1];
  return outboundSlice.segmentDetails[0].airlineCode ===
    returnSlice.segmentDetails[0].airlineCode
    ? MultiTicketType.single
    : MultiTicketType.hackerFare;
};

export const getMultiTicketSavings = (
  state: FlightStateWithAndWithoutValue
) => {
  const tripResultEventProperties =
    state.context.flightShop.tripResultEventProperties;
  if (tripResultEventProperties) {
    // we only want three hopperMultiTicket_savings_currency fields
    return {
      hopperMultiTicket_savings_currency:
        tripResultEventProperties["hopperMultiTicket_savings_currency"],
      hopperMultiTicket_savings_usd:
        tripResultEventProperties["hopperMultiTicket_savings_usd"],
      hopperMultiTicket_savings:
        tripResultEventProperties["hopperMultiTicket_savings"],
    };
  }
  return null;
};

export const getFlightFulfillTrackingProperties = (
  state: FlightStateWithAndWithoutValue
) => {
  const chooseTravelerProperties = getChooseTravelerProperties(state);
  const selectedPassengers = getAllSelectedUserPassengersParent(state);

  const selectedTrip = getSelectedTripParent(state);
  const selectedTripDetails = getSelectedTripDetailsParent(state);
  const fare = selectedTripDetails.fareDetails.find(
    (f) =>
      f.id === selectedTrip.outgoingFareId || f.id === selectedTrip.returnFareId
  );
  const carrier: string | undefined =
    fare &&
    fare.slices.length > 0 &&
    fare.slices[0].fareDetails.segments.length > 0
      ? fare?.slices[0].fareDetails.segments[0].validatingCarrierCode
      : undefined;
  const outgoingHackerFare =
    selectedTripDetails.fareDetails.find(
      (f) => f.id === selectedTrip.outgoingFareId
    )?.multiTicket || false;

  const returnHackerFare =
    selectedTripDetails.fareDetails.find(
      (f) => f.id === selectedTrip?.returnFareId
    )?.multiTicket || false;

  const quotedFlight = getQuotedFlightProduct(state);

  const agentLocator = getAgentLocator(state);

  const multiTicketSavings =
    outgoingHackerFare || returnHackerFare ? getMultiTicketSavings(state) : {};

  return {
    properties: {
      ...chooseTravelerProperties,
      ...multiTicketSavings,
      booking_session_id: quotedFlight?.bookingSessionId || "",
      carrier: carrier || "",
      is_agent_session: false,
      pax_total: selectedPassengers.length,
      advance: `${dayjs(selectedTripDetails.slices[0].departureTime).diff(
        dayjs(),
        "day"
      )} days`,
      is_multi_ticket: outgoingHackerFare || returnHackerFare,
      outbound_vi_layovers: getVirtualInterlineLayovers(fare, true),
      return_vi_layovers:
        chooseTravelerProperties.trip_type === "round_trip"
          ? getVirtualInterlineLayovers(fare, false)
          : null,
      fare_class: fare
        ? getHopperFareRatingName(fare?.slices[0].fareShelf?.rating)
        : "",
      agent_locator: agentLocator,
      rewards_currency:
        quotedFlight?.pricingSummary?.totalPricing?.total?.fiat?.currencyCode,
    },
  };
};
